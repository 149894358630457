import axios from "axios";
import moment from "moment";
import { encryptStorage } from "@/lib/encrypted-storage";
import VueCookies from 'vue-cookies';

const KEYCLOAK_BASE_URL = process.env.VUE_APP_KEYCLOAK_BASE_URL;
const KEYCLOAK_CLIENT = process.env.VUE_APP_KEYCLOAK_CLIENT;
const KEYCLOAK_COMMON_REALM = process.env.VUE_APP_KEYCLOAK_COMMON_REALM;
const SSO_REALM = process.env.VUE_APP_SSO_REALM;

function storeResponseData(response) {
    localStorage.setItem("token", response.access_token);
    localStorage.setItem("refreshToken", response.refresh_token);
    localStorage.setItem("tokenExpiresInSec", response.expires_in);
    let currentDate = moment().add(response.expires_in, "seconds");
    let expiresIn = currentDate.format("YYYY-MM-DD HH:mm:ss");
    localStorage.setItem("tokenExpires", expiresIn);

    let tokenInfo = decodeKeycloakToken(response.access_token);
    let resourceAccess = JSON.stringify(tokenInfo.resource_access);
    encryptStorage.setItem("resource_access", resourceAccess);

    VueCookies.set("token", response.access_token);
    VueCookies.set("refreshToken", response.refresh_token);
    VueCookies.set("tokenExpiresInSec", response.expires_in);
    VueCookies.set("tokenExpires", expiresIn);
    VueCookies.set("subDomain", window.location.hostname, '', '/', getMainDomain());
}

function getMainDomain() {
    const hostname = window.location.hostname; // e.g., "pre-platform.isportz.loc"
    const parts = hostname.split(".");

    // Get the last two parts of the hostname (e.g., "isportz.loc")
    if (parts.length > 2) {
        return `${parts[parts.length - 2]}.${parts[parts.length - 1]}`;
    }
    return hostname; // For cases like localhost or top-level domains
}

function updateToken() {
    const formData = new URLSearchParams({
        client_id: KEYCLOAK_CLIENT,
        grant_type: "refresh_token",
        refresh_token: localStorage.getItem("refreshToken"),
    });
    const config = {
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "application/x-www-form-urlencoded",
        },
    };
    let realm = window.location.hostname.split(".")[0];
    axios
        .post(
            KEYCLOAK_BASE_URL + `/realms/${realm}/protocol/openid-connect/token`,
            formData,
            config
        )
        .then((res) => {
            storeResponseData(res.data);
        })
        .catch(() => {
            goToLogin();
        });
}

/**
 * Logs in a user using Keycloak authentication.
 *
 * @param {Object} formData - The user login form data.
 * @param {string} formData.username - The user's username/email address.
 * @param {string} formData.password - The user's password.
 *
 * @returns {Object} response A Promise that resolves to the authentication response data.
 * // Output: { access_token: '...', refresh_token: '...', ... }
 * @throws {Error} If there is an error during the login process.
 */
const login = async function login(formData) {
    try {
        let loginForm = new URLSearchParams({
            client_id: KEYCLOAK_CLIENT,
            grant_type: "password",
            username: formData.username,
            password: formData.password,
        });

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/x-www-form-urlencoded",
            },
        };

        let realm = window.location.hostname.split(".")[0];
        const response = await axios.post(
            KEYCLOAK_BASE_URL + `/realms/${realm}/protocol/openid-connect/token`,
            loginForm,
            config
        );

        let responseData = response.data;
        storeResponseData(responseData);
        return responseData;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

const sassLogin = async function sassLogin(formData) {
    try {
        let loginForm = new URLSearchParams({
            client_id: KEYCLOAK_CLIENT,
            grant_type: "password",
            username: formData.username,
            password: formData.password,
        });

        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/x-www-form-urlencoded",
            },
        };


        const response = await axios.post(
            KEYCLOAK_BASE_URL + `/realms/${KEYCLOAK_COMMON_REALM}/protocol/openid-connect/token`,
            loginForm,
            config
        );

        let responseData = response.data;
        storeResponseData(responseData);
        return responseData;
    } catch (error) {
        console.log(error);
        throw error;
    }
};

/**
 * Logs out the user by invalidating the current keycloak session and redirect to MMS login.
 */
const logout = function logout(url=null) {
    try {
        const formData = new URLSearchParams({
            client_id: KEYCLOAK_CLIENT,
            refresh_token: localStorage.getItem("refreshToken"),
        });
        const config = {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Accept: "application/x-www-form-urlencoded",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
        };
        let realm = window.location.hostname.split(".")[0];
        if(realm == SSO_REALM) {
            realm = VueCookies.get('subDomain').split(".")[0];
        }
        axios
            .post(
                KEYCLOAK_BASE_URL + `/realms/${realm}/protocol/openid-connect/logout`,
                formData,
                config
            )
            .finally(() => {
                goToLogin(url);
            });
    } catch (error) {
        console.error(error);
        goToLogin();
    }
};

/**
 * Periodically checks and updates the access token and if page is idle, it's redirected to MMS login
 */
const refreshToken = function refreshToken() {
    setInterval(() => {
        let tokenExpires = localStorage.getItem("tokenExpires");
        const currentDate = moment();
        const expiresDate = moment(tokenExpires);
        if (currentDate.isAfter(expiresDate)) {
            goToLogin();
        }
    }, 2000);

    let tokenExpiresInSec = localStorage.getItem("tokenExpiresInSec");
    if (tokenExpiresInSec) {
        updateToken();
        tokenExpiresInSec = parseInt(tokenExpiresInSec) * 1000 - 60000;
        setInterval(() => {
            updateToken();
        }, tokenExpiresInSec);
    } else {
        goToLogin();
    }
};

/**
 * Decodes a Keycloak token and returns the decoded payload.
 *
 * @param {string} [token] - The token to decode. Defaults to the stored access token.
 * @returns {Object} The decoded keycloak token data.
 */
const decodeKeycloakToken = function decodeKeycloakToken(
    token = localStorage.getItem("token")
) {
    let base64Payload = token.split(".")[1];
    let payload = decodeURIComponent(
        atob(base64Payload)
        .split("")
        .map(function(c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(payload);
};

/**
 * Retrieves user roles from encrypted storage.
 *
 * @param {string|null} client - The client ID for which to retrieve roles. Defaults to VUE_APP_KEYCLOAK_CLIENT env if not provided. Examples: "mms", "ctms", "ems","lms"
 * @returns {Array} An array containing user roles, e.g., ["admin", "member"]. Returns an empty array if no roles are found.
 */
const getRoles = function getRoles(client = null) {
    try {
        let clientId = client ? client : KEYCLOAK_CLIENT;
        let roles = encryptStorage.getItem("resource_access");
        return roles[clientId].roles;
    } catch (error) {
        return [];
    }
};

const goToLogin = function goToLogin(url=null) {
    localStorage.clear();
    VueCookies.remove("token");
    VueCookies.remove("refreshToken");
    VueCookies.remove("tokenExpiresInSec");
    VueCookies.remove("tokenExpires");
    let realm = window.location.hostname.split(".")[0];
    if(realm == SSO_REALM) {
        let domain  = VueCookies.get("subDomain");
        VueCookies.remove("subDomain");
        VueCookies.remove("subDomain", getMainDomain());
        window.location.href = 'https://' + domain + "/mms/login?loginStatus=logout";
    } else {
        let loginUrl = window.location.origin;
        if (url == null) {
            window.location.href = loginUrl + "/mms/login";
        } else {
            window.location.href = loginUrl + `/mms/login/${url[5]}/${url[6]}/${url[7]}/${url[8]}`;
        }
    }
};

const canAccessMenu = function canAccessMenu(roles, app, access = null) {
    try {
        /* lms client url base check roles */
        if (app == 'lms') {
            app =  'lms';
        }

        const menuRoleArr = roles ? roles.split(",").map((role) => role.trim().toLowerCase()) : "";
        const appRoles = getRoles(app).map((role) => role.toLowerCase());
        const appDefaultModules = ["ems", "mms", "ctms", "fundraiser", "subscriptions", "lms"];
        if (!appDefaultModules.includes(app)) {
            return ([undefined, null, ''].includes(access) ? true : (this.$store.getters.fetchUserPermissions.includes('mmsteam') && !this.$store.getters.fetchUserPermissions.includes(access) ? false : true));
        } else if (appRoles.length && app === "ctms") {
            const userType = this.$encryptStorage.getItem("user_type");
            let userRole;
            if (userType) {
                const appRolesMap = {
                    2: "Club Admin",
                    3: "Team Admin",
                    4: "End User",
                };
                userRole = appRolesMap[userType].toLowerCase();
            } else {
                userRole =
                    appRoles.length && appRoles.includes("club admin") ?
                    "club admin" :
                    "end user";
            }
            return menuRoleArr.includes(userRole);
        } else if (appRoles.length && appDefaultModules.includes(app)) {
            return menuRoleArr.some((r) => appRoles.includes(r));
        } else {
            return false;
        }
    } catch (error) {
        return false;
    }
};

const storeLoginResponse = function storeLoginResponse(response){
    storeResponseData(response);
};
export {
    login,
    logout,
    refreshToken,
    decodeKeycloakToken,
    goToLogin,
    getRoles,
    canAccessMenu,
    storeLoginResponse,
    sassLogin
};